const $ = require('jquery');
import { BrowserService } from './services/browser/browser.service';
export const browserService = new BrowserService()

const UIkit = require('uikit');
const slick = require('slick-carousel/slick/slick');

import header from "../components/header/header";
import heroslider from "../components/hero/hero";

window.addEventListener("DOMContentLoaded", () => {

  header();
  heroslider();

}, { once: true });
