const $ = require('jquery');


/* functions */
function heroslider() {

    const sliderEl = $('.js-hero');

    sliderEl.each(function () {

        const that = $(this);

        $(this).slick({
            dots: true,
            arrows: false,
            centerMode: true,
            slidesToShow: 1,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
        });
    });
}

export default heroslider;